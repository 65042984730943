<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card title="Detail Bukti Akuisisi DOKU">
        <b-overlay :show="loading" rounded="sm">
          <b-form-group label="Nama PIC" label-for="name">
            <b-form-input
              id="name"
              type="text"
              v-model="form.name"
              :state="state.name"
              required
            />
          </b-form-group>
          <b-form-group label="No Hp" label-for="phone">
            <b-form-input
              id="phone"
              maxlength="15"
              type="number"
              v-model="form.phone"
              :state="state.phone"
              required
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </b-form-group>
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              maxlength="50"
              type="text"
              v-model="form.email"
              :state="state.email"
              required
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </b-form-group>
          <b-form-group label="Tanggal Pengajuan" label-for="registerDate">
            <flat-pickr
              v-model="form.proven_at"
              class="form-control"
              :config="datepicker"
              :state="state.proven_at"
              required
            />
          </b-form-group>
          <b-form-group label="Nama Merchant DOKU" label-for="merchant_name">
            <b-form-input
              id="merchant_name"
              type="text"
              v-model="form.merchant_name"
              :state="state.merchant_name"
            />
          </b-form-group>
          <b-form-group label="Note" label-for="note">
            <b-form-input
              id="note"
              v-model="form.note"
              :state="state.note"
            />
          </b-form-group>
          <b-button block class="mt-2" variant="success" @click="onSend"
            >Kirim</b-button
          >
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormFile,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ImageUploader from "vue-image-upload-resize";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormFile,
    flatPickr,
    ImageUploader,
    BOverlay,
    BFormSelect,
  },
  data() {
    return {
      loading: false,
      hasImage: false,
      datepicker: {
        dateFormat: "d-m-Y",
      },
      state: {
        name: null,
        phone: null,
        email: null,
        proven_at: null,
        merchant_name: null,
        note: null,
      },
      form: {
        name: "",
        phone: "",
        email: "",
        proven_at: "",
        merchant_name:"",
        note: "",
      },
    };
  },
  created() {},
  methods: {
    validation() {
      if (
        this.form.name.length == 0 ||
        this.form.name == " " ||
        this.form.name == "  " ||
        this.form.name == "   "
      ) {
        this.$bvToast.toast("Nama PIC tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.phone.length == 0) {
        this.$bvToast.toast("No Hp tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.phone.length < 9) {
        this.$bvToast.toast("No Hp min harus 10 digit", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.proven_at.length == 0) {
        this.$bvToast.toast("Tanggal terdaftar tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.email.length == 0) {
        this.$bvToast.toast("Email tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.merchant_name.length == 0) {
        this.$bvToast.toast("Nama Merchant tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      

      if (
        this.form.name.length > 0 &&
        this.form.name != " " &&
        this.form.name != "  " &&
        this.form.name != "   " &&
        this.form.phone.length > 9 &&
        this.form.proven_at.length > 0 &&
        this.form.merchant_name.length > 0 &&
        this.form.email.length > 0
      ) {
        return true;
      }
    },
    onSend() {
      if (this.validation()) {
        console.log(this.form);
        this.loading = true;
        this.$http
          .post("/report-doku/add", this.form)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$router.push("/merchant-doku");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
    onContext(ctx) {
      var $selected = ctx.selectedYMD;
      var $arr = $selected.split("-");
      this.form.proven_at = $arr[2] + "-" + $arr[1] + "-" + $arr[0];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
